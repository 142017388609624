.section-account{
    margin-top: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-account *{
    font-family: Inter;
}
.section-account .account-details{
    width: 400px;
    height: auto;
    padding: 40px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    background-color: var(--quaternary-color);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    gap: 15px;
}
.section-account .account-details .section-title{
    font-size: 25px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}
.section-account .account-details .form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.section-account .account-details .form .form-group{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}
.section-account .account-details .form .form-group label{
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
}
.section-account .account-details .form .form-group input{
    width: auto;
    height: 20px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
}
.section-account .account-details .form .form-group .btn{
    width: 100%;
    height: 40px;
    background-color: var(--secondary-color);
    color: var(--quaternary-color);
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}
.section-account .account-details .go-to-login{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 12px;
}
.section-account .account-details .go-to-login a{
    text-decoration: none;
    color: var(--primary-color);
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .section-account{
        margin-top: 120px;
    }
    .section-account .account-details{
        width: 90%;
        padding: 20px;
        background-color: transparent;
        box-shadow: none;
        border: none;
        gap: 5px;
    }
    .section-account .account-details .section-title{
        font-size: 20px;
    }
    .section-account .account-details .form{
        gap: 5px;
    }
    .section-account .account-details .form .form-group label{
        font-size: 12px;
    }
    .section-account .account-details .form .form-group input{
        padding: 8px;
    }
    .section-account .account-details .form .form-group .btn{
        height: 35px;
        font-size: 13px;
    }
}
@media screen and (max-width: 400px) {
    .section-account .account-details{
        width: 90%;
        padding: 20px;
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .section-account .account-details .section-title{
        font-size: 18px;
    }
}