:root{
    --item-width: 300px;
    --item-height: 400px;
}
.item{
    width: auto;
    height: auto;
    margin-top: 20px;
}
.item .item-img{
    width: var(--item-width);
    height: var(--item-height);
    overflow: hidden;
}
.item .item-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.item .item-img img:hover{
    transform: scale(1.1);
    transition: transform 0.5s;
}
.item .item-details{
    margin-top: 10px;
    position: relative;
    max-width: var(--item-width);
}
.item .item-details .item-info{
    display: flex;
    align-items: left;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    font-family: Inter;
}
.item .item-details .item-info a{
    text-decoration: none;
    color: var(--text-color);
}
.item .item-details .item-info .item-categories{
    color: var(--text-color);
    opacity: 45%;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}
.item .item-details .item-info .item-name{
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}
.item .item-details .item-info .item-price{
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    gap: 10px;
}
.item .item-details .item-info .item-price span.mrp{
    color: var(--text-color);
    opacity: 45%;
    text-decoration: line-through;
}
.item .item-details .item-cta{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.item .item-details .item-cta .cta-btn{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    border-radius: 100%;
    cursor: pointer;
}
.item .item-details .item-cta .cta-btn img{
    width: 40%;
    height: auto;
}

@media screen and (max-width: 1000px) {
    :root{
        --item-width: 250px;
        --item-height: 350px;
    }
}
@media screen and (max-width: 850px) {
    :root{
        --item-width: 200px;
        --item-height: 300px;
    }
    .item .item-details .item-info .item-name{
        font-size: 14px;
    }
}
@media screen and (max-width: 700px) {
    :root{
        --item-width: 180px;
        --item-height: 280px;
    }
}
@media screen and (max-width: 640px) {
    :root{
        --item-width: 150px;
        --item-height: 200px;
    }
}