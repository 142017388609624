.section-address{
    margin-top: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-address *{
    font-family: Inter;
}
.section-address .address-con{
    width: 600px;
    height: auto;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
}
.section-address .address-con .section-title{
    font-size: 25px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}
.section-address .address-con .address{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--quaternary-color);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    gap: 20px;
    position: relative;
}
.section-address .address-con .address .address-info{
    padding: 10px 20px 40px 20px;
    font-size: 13px;
}
.section-address .address-con .address .address-info .address-title{
    font-size: 20px;
    font-weight: 500;
    width: 100%;
}
.section-address .address-con .address .address-actions{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
}
.section-address .address-con .address .address-actions .btn{
    width: 50%;
    height: 100%;
    border: none;
    cursor: pointer;
}
.section-address .address-con .address .address-actions .editBtn{
    background-color: var(--primary-color);
    color: var(--quaternary-color);
    border-radius: 0 0 0 5px;
}
.section-address .address-con .address .address-actions .deleteBtn{
    background-color: var(--quaternary-color);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 0 0 5px 0;
}