:root{
    --item-width: 300px;
}

.freeform-grid-item {
    width: var(--item-width);

    break-inside: avoid;
    margin-bottom: 20px;
}

.freeform-grid-item .item-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
}

.freeform-grid-item .item-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    transition: transform 0.5s;
}

.freeform-grid-item .item-img img:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 1000px) {
    :root {
        --item-width: 250px;
    }
}
@media screen and (max-width: 850px) {
    :root {
        --item-width: 300px;
    }
}
@media screen and (max-width: 700px) {
    :root {
        --item-width: 100%;
    }
}
@media screen and (max-width: 600px) {
    :root {
        --item-width: 100%;
    }
}