.policy{
    margin: 0 auto;
    width: 80%;
    padding: 20px;
    width: auto;
    margin-top: 100px;
    color: var(--text-color);
}
.policy *{
    font-family: Inter;
}
.policy a{
    color: var(--text-color);
    text-decoration: none;
}
.policy p, .title{
    margin: 0;
}

.policy .section-head{
    text-align: left;
}
.policy .section-head .title{
    font-size: 100px;
    font-weight: 600;
    margin-bottom: 22px;
    font-family: Jost;
    font-weight: 900;
}
.policy .section-head .description{
    font-size: 18px;
    width: 90%;
}

.policy .rule{
    display: flex;
    flex-direction: column;
}
.policy .rule .head{
    font-size: 23px;
    font-weight: 600;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    gap: 10px;
}
.policy .rule .head .title{
    flex-shrink: 0;
}
.policy .rule .head .line{
    flex-grow: 1;
    height: 1px;
    background-color: var(--border-light-color);
}
.policy .rule .head .hashtag{
    flex-shrink: 0;
}

.policy .rule .body{
    font-size: 18px;
    line-height: 1.5;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.policy .rule .body .sub-title{
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}
.policy .rule .body a{
    cursor: pointer;
    text-decoration: underline;
}


@media screen and (max-width: 1100px){
    .policy .section-head .title{
        font-size: 80px;
    }
}
@media screen and (max-width: 820px){
    .policy .section-head .title{
        font-size: 60px;
    }
}
@media screen and (max-width: 650px){
    .policy .section-head .title{
        font-size: 50px;
    }
}
@media screen and (max-width: 580px){
    .policy .section-head .title{
        font-size: 40px;
    }
    .policy .section-head .description{
        width: 100%;
    }
}
@media screen and (max-width: 480px){
    .policy .section-head .title{
        font-size: 30px;
    }
    .policy .section-head .description{
        text-align: justify;
    }
}
@media screen and (max-width: 360px){
    .policy .section-head .title{
        font-size: 25px;
    }
}