.shopping-cart{
    margin-top: 100px;
    padding: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
}
.shopping-cart *{
    font-family: 'Poppins', sans-serif;
}

.shopping-cart .section-head{
    display: flex;
    align-items: center;
    padding-left: 80px;
    /* width: 100%; */
    border-bottom: 1px solid var(--border-color);
    text-transform: uppercase;
}
.shopping-cart .section-head .section-title{
    font-size: 30px;
    font-weight: 600;
    font-family: 'Monument Extended';
    color: var(--text-color);
}

.shopping-cart .cart-body{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    /* width: 100%; */
    margin-top: 20px;
    gap: 100px;
    margin: 30px 80px;
}
.shopping-cart .cart-body .cart-items{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    width: 50%;
    margin-top: 20px;
    /* gap: 30px; */
}
.shopping-cart .cart-body .cart-items .line{
    width: 100%;
    height: 1px;
    background-color: var(--border-color);
    margin: 0px 0 30px 0;
    opacity: 0.3;
}
.shopping-cart .cart-body .cart-items .empty-cart{
    color: var(--text-color);
}
.shopping-cart .cart-body .cart-items .cart-item{
    display: flex;
    /* justify-content: center; */
    flex-direction: row;
    /* border-bottom: 1px solid var(--border-color); */
    gap: 20px;
}
.shopping-cart .cart-body .cart-items .cart-item .item-left{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.shopping-cart .cart-body .cart-items .cart-item .item-left .product-image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 155px;
    height: 185px;
}
.shopping-cart .cart-body .cart-items .cart-item .item-left .product-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.shopping-cart .cart-body .cart-items .cart-item .item-left .product-quantity{
    display: flex;
    align-items: center;
    gap: 7px;
    height: 50px;
    color: var(--text-color);
    border-radius: 100px;
    margin: 20px 0;
    gap: 20px;
}
.shopping-cart .cart-body .cart-items .cart-item .item-left .product-quantity .quantity-box{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 100px; */
}
.shopping-cart .cart-body .cart-items .cart-item .item-left .product-quantity button{
    width: 40px;
    height: 40px;
    font-size: 18px;
    cursor: pointer;
    border: none;
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shopping-cart .cart-body .cart-items .cart-item .item-right{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    color: var(--text-color);
}
.shopping-cart .cart-body .cart-items .cart-item .item-right .product-details{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    /* gap: 10px; */
}
.shopping-cart .cart-body .cart-items .cart-item .item-right .product-details .details-head{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 50px;
}
.shopping-cart .cart-body .cart-items .cart-item .item-right .product-details .product-title{
    font-size: 20px;
    font-weight: 600;
    color: var(--text-color);
}
.shopping-cart .cart-body .cart-items .cart-item .item-right .product-details .product-title a{
    text-decoration: none;
    color: var(--text-color);
}
.shopping-cart .cart-body .cart-items .cart-item .item-right .product-details .product-specifications{
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-transform: capitalize;
}
.shopping-cart .cart-body .cart-items .cart-item .item-right .product-details .product-specifications div .label{
    color: var(--text-light-color);
}
.shopping-cart .cart-body .cart-items .cart-item .item-right .product-price{
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);
}

.shopping-cart .cart-body .cart-summary{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    padding: 0;
}
.shopping-cart .cart-body .cart-summary .summary-title{
    font-size: 30px;
    font-weight: 600;
    color: var(--text-color);
    margin: 20px 0;
}
.shopping-cart .cart-body .cart-summary .summary-details{
    display: flex;
    flex-direction: column;
    color: var(--text-color);
}
.shopping-cart .cart-body .cart-summary .summary-details div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.shopping-cart .cart-body .cart-summary .summary-details div span{
    font-size: 13px;
    color: var(--text-light-color);
}
.shopping-cart .cart-body .cart-summary .line{
    width: 100%;
    height: 1px;
    background-color: var(--border-color);
    margin: 20px 0;
    opacity: 0.3;
}
.shopping-cart .cart-body .cart-summary .cart-total{
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--text-color);
    margin-bottom: 15px;
}
.shopping-cart .cart-body .cart-summary .cart-cta{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
/* .shopping-cart .cart-body .cart-summary .cart-cta .checkoutBtn{
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0px;
    font-size: 17px;
    text-transform: uppercase;
    text-decoration: none;
} */
.shopping-cart .cart-body .cart-summary .tnc{
    font-size: 12px;
    color: var(--text-light-color);
    margin-top: 10px;
}
.shopping-cart .cart-body .cart-summary .tnc a{
    color: var(--text-color);
}



.shopping-cart .cart-body .cart-summary .cart-cta .checkoutBtn {
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    background-color: var(--secondary-color);  /* Base background color */
    color: var(--primary-color);  /* Text color */
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0px;
    font-size: 17px;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    overflow: hidden;
}

.shopping-cart .cart-body .cart-summary .cart-cta .checkoutBtn:hover {
    background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 100%);
    background-size: 200%;
    background-position: center;
    transition: background-color 0.2s ease;
}

@media screen and (max-width: 1150px) {
    .shopping-cart .cart-body {
        flex-direction: column;
        gap: 20px;
        margin: 30px 30px;
    }
    .shopping-cart .cart-body .cart-summary {
        width: 100%;
    }
    .shopping-cart .cart-body .cart-items {
        width: 100%;
    }
}