.related-products{
    margin-top: 20px;
    overflow: hidden;
}
.related-products .section-head{
    margin: 0 50px;
    text-align: left;
}
.related-products .section-head .section-title{
    font-size: 30px;
    font-weight: 600;
    font-family: "Poppins";
    margin-bottom: 10px;
    text-transform: uppercase;
}
.related-products .section-head .line{
    width: 100%;
    height: 1px;
    background-color: var(--primary-color);
    margin-bottom: 20px;
    opacity: 0.6;
}
.related-products .slider{
    margin-top: 30px;
    padding: 15px 60px;
    position: relative;
    display: flex;
    align-items: center;
}
.related-products .slider .arrow-btn{
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    background-color: var(--quaternary-color);
    color: var(--primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.8;
    z-index: 3;
}
.related-products .slider i{
    opacity: 0.7;
}
.related-products .slider .arrow-btn.left{
    left: 50px;
}
.related-products .slider .arrow-btn.right{
    right: 50px;
}

.related-products .slider .items{
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 30px;
    font-family: Inter;
    padding: 0 0;
    position: relative;
    overflow: hidden;
}
.related-products .slider .items .item{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    gap: 10px;
    cursor: pointer;
}
.related-products .slider .items .item .product-image{
    width: 350px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.related-products .slider .items .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

@media screen and (max-width: 700px) {
    .related-products .section-head{
        margin: 0 20px;
    }
    .related-products .slider{
        padding: 15px 20px;
    }
    .related-products .slider .arrow-btn.left{
        left: 20px;
    }
    .related-products .slider .arrow-btn.right{
        right: 20px;
    }
    .related-products .slider .arrow-btn{
        opacity: 0.6;
    }
}
@media screen and (max-width: 480px) {
    .related-products .slider .items{
        gap: 10px;
    }
    .related-products .slider .items .item .product-image{
        width: 200px;
        height: 350px;
    }
}