/* 
.freeform-shop{
    margin: 130px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}
.freeform-shop .shop-title{
    font-size: 48px;
    font-family: Inter;
    font-weight: 400;
    text-transform: capitalize;
    color: var(--primary-color);
    opacity: 70%;
    margin-bottom: 20px;
}
.freeform-shop .products{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
}
.freeform-shop .products .items{
    column-count: 4;
    column-gap: 10px;
    gap: 20px;
}

@media screen and (max-width: 1360px) {
    .freeform-shop .products .items{
        column-count: 3;
    }
}
@media screen and (max-width: 550px) {
    .freeform-shop .products .items{
        gap: 15px;
    }
}
@media screen and (max-width: 520px) {
    .freeform-shop .products .items{
        column-count: 2;
    }
}
@media screen and (max-width: 530px) {
    .freeform-shop .products{
        column-count: 2;
    }
}
@media screen and (max-width: 370px) {
    .freeform-shop {
        margin: 100px 10px;
    }
} */




.freeform-shop{
    margin: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}
.freeform-shop .shop-head{
    width: 100%;
    padding-left: 50px;
    border-bottom: 1px solid var(--border-light-color);
    margin-bottom: 50px;
}
.freeform-shop .shop-title{
    font-size: 30px;
    font-family: Poppins;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--text-color);
    font-family: "Monument Extended";
}
.freeform-shop .products{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
}
.freeform-shop .products .items{
    column-count: 4;
    column-gap: 10px;
    gap: 20px;
}

@media screen and (max-width: 1360px) {
    .freeform-shop .products .items{
        column-count: 3;
    }
}
@media screen and (max-width: 850px) {
    .freeform-shop .products .items{
        column-count: 2;
    }
}
@media screen and (max-width: 600px) {
    .freeform-shop .products .items{
        width: 100%;
        padding: 0 20px;
    }
}
@media screen and (max-width: 550px) {
    .freeform-shop .products .items{
        gap: 15px;
    }
}
@media screen and (max-width: 370px) {
    .freeform-shop {
        margin: 100px 10px;
    }
    .freeform-shop .products .items{
        margin-left: 15px;
        margin-right: 15px;
    }
}