.navbar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: 15px;
    font-family: Inter;
    position: relative;
    gap: 30px;
}
.navbar .nav-item{
    cursor: pointer;
}
.nav-link{
    text-decoration: none;
    color: var(--primary-color);
}