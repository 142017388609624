.search-results{
    margin: 140px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}
.search-results .search-title{
    font-size: 23px;
    font-family: Inter;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--text-color);
    opacity: 70%;
    margin-bottom: 20px;
}
.search-results .products{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
}
.search-results .products .message{
    color: var(--text-color);
    opacity: 70%;
    text-align: center;
}
.search-results .products .items{
    padding: 0 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

@media screen and (max-width: 1360px) {
    .search-results .products .items{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 550px) {
    .search-results .products .items{
        gap: 15px;
    }
}
@media screen and (max-width: 520px) {
    .search-results .products .items{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 530px) {
    .search-results .products{
        grid-template-columns: repeat(2, 2fr);
    }
}
@media screen and (max-width: 370px) {
    .search-results {
        margin: 100px 10px;
    }
}