
.freeform-search-results{
    margin: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}
.freeform-search-results .search-head{
    width: 100%;
    padding-left: 50px;
    border-bottom: 1px solid var(--border-light-color);
    margin-bottom: 50px;
}
.freeform-search-results .search-title{
    font-size: 30px;
    font-family: Poppins;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--text-color);
    font-family: "Monument Extended";
}
.freeform-search-results .products{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
}
.freeform-search-results .products .items{
    column-count: 4;
    column-gap: 10px;
    gap: 20px;
}
.freeform-search-results .products .message{
    color: var(--text-color);
    opacity: 70%;
    text-align: center;
}

@media screen and (max-width: 1360px) {
    .freeform-search-results .products .items{
        column-count: 3;
    }
}
@media screen and (max-width: 850px) {
    .freeform-search-results .products .items{
        column-count: 2;
    }
}
@media screen and (max-width: 600px) {
    .freeform-search-results .products .items{
        width: 100%;
        padding: 0 20px;
    }
}
@media screen and (max-width: 550px) {
    .freeform-search-results .products .items{
        gap: 15px;
    }
}
@media screen and (max-width: 370px) {
    .freeform-search-results {
        margin: 100px 10px;
    }
    .freeform-search-results .products .items{
        margin-left: 15px;
        margin-right: 15px;
    }
}