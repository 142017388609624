/* .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}  
.overlay-content {
    color: white;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.overlay-content .overlay-close {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 30px;
    cursor: pointer;
}

.overlay-content .search-bar {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    box-shadow: 0 0 6px rgba(255, 0, 247, 0.5);
    border-radius: 10px;
}
.overlay-content .search-bar input {
    width: 100%;
    height: 100%;
    padding: 0 50px 0 10px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px;
    border: none;
    outline: none;
    color: var(--secondary-color);
    text-decoration: none;
}
.overlay-content .search-bar svg{
    position: absolute;
    right: 20px;
    color: var(--secondary-color);
}

@media screen and (max-width: 600px){
    .overlay-content{
        width: 85%;
    }
} */


.search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
/* make overlay blur */
.search-overlay:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
}
.overlay-content {
    text-align: center;
    width: 100%;
    max-width: 600px;
    position: absolute;
    top: 50px;
    right: 50px;
}
.overlay-content *{
    font-family: Poppins;
}

.overlay-content .search-box {
    display: flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--background-color);
    padding: 30px 30px 0 30px;
    border-radius: 20px;
}
.overlay-content .search-box .search-head{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 50px;
    gap: 30px;
}
.overlay-content .search-box .search-head .search-bar {
    width: 100%;
    height: 100%;
}
.overlay-content .search-box .search-head .search-bar input {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px;
    color: var(--text-color);
    background-color: var(--background-color);
    border: 1px solid var(--border-light-color);
    outline: none;
}
.overlay-content .search-box .search-head .search-btn{
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--accent-color);
    border-radius: 10px;
    cursor: pointer;
    color: var(--text-color);
}

.overlay-content .search-box .recommendation-container{
    display: flex;
    align-items: center;
    flex-direction: roe;
    gap: 50px;
    margin: 50px 0;
}
.overlay-content .search-box .recommendation-container .recommendation{
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--background-color);
    color: var(--text-color);
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.overlay-content .search-box .recommendation-container .recommendation .product-image{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.overlay-content .search-box .recommendation-container .recommendation img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.overlay-content .search-box .recommendation-container .recommendation .product-title{
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
    display: none;
}

@media screen and (max-width: 600px){
    .overlay-content{
        width: 85%;
    }
}
