.product-display{
    margin-top: 100px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--background-color);
    color: var(--text-color);
}
.product-display *{
    font-family: 'Poppins', sans-serif;
}
.product-display .product-main{
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 50px;
    padding: 20px 50px;
}
.product-display .product-main .product-gallery{
    width: 35%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    position: relative;
}
.product-display .product-main .product-gallery .image-arrow{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 40px;
    color: var(--border-light-color);
}
.product-display .product-main .product-gallery .image-arrow:hover{
    color: var(--text-light-color);
}
.product-display .product-main .product-gallery .image-arrow-left{
    position: absolute;
    left: 10px;
}
.product-display .product-main .product-gallery .image-arrow-right{
    position: absolute;
    right: 10px;
}
.product-display .product-main .product-gallery .product-image{
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.product-display .product-main .product-gallery .product-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.product-display .product-main .product-gallery .gallery-dots{
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.product-display .product-main .product-gallery .gallery-dots .dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--border-light-color);
    cursor: pointer;
    margin: 0 5px;
}
.product-display .product-main .product-gallery .gallery-dots .dot.active{
    background-color: var(--border-color);
}

.product-display .product-main .product-details{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.product-display .product-main .product-details .product-title{
    font-size: 40px;
    font-weight: 600;
}
.product-display .product-main .product-details .product-price{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.product-display .product-main .product-details .product-price .line-cut{
    text-decoration: line-through;
    color: var(--text-light-color);
    font-size: 15px;
    line-height: 2.5;
}
.product-display .product-main .product-details .product-price .sp{
    font-size: 25px;
    font-weight: 600;
    color: var(--secondary-color);
}
.product-display .product-main .product-details .discount-label{
    margin-bottom: 50px;
    font-size: 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--border-light-color);
    padding: 5px 10px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: -10px;
}

.product-display .product-main .product-details label{
    font-size: 20px;
    text-transform: uppercase;
}

.product-display .product-main .product-details .color-menu{
    display: flex;
    flex-direction: column;
}
.product-display .product-main .product-details .color-menu .line{
    width: 250px;
    height: 1px;
    background-color: var(--border-light-color);
}
.product-display .product-main .product-details .color-menu .menu-options{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin-top: 20px;
    flex-direction: row;
}
.product-display .product-main .product-details .color-menu .menu-options .option{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    padding: 5px;
    border: 1px solid var(--border-light-color);
}
.product-display .product-main .product-details .color-menu .menu-options .option.active{
    border: 2px solid var(--border-color);
}
.product-display .product-main .product-details .color-menu .menu-options .option .color-preview{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.product-display .product-main .product-details .size-menu{
    display: flex;
    flex-direction: column;
}
.product-display .product-main .product-details .size-menu .line{
    width: 250px;
    height: 1px;
    background-color: var(--border-light-color);
}
.product-display .product-main .product-details .size-menu .label{
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    /* gap: 50px; */
    width: 250px;
}
.product-display .product-main .product-details .size-menu .label .size-guide-btn{
    font-size: 14px;
    cursor: pointer;
    line-height: 2;
    text-decoration: underline;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: var(--text-light-color);
}
.product-display .product-main .product-details .size-menu .menu-options{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
    justify-content: row;
}
.product-display .product-main .product-details .size-menu .menu-options .option{
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.product-display .product-main .product-details .size-menu .menu-options .option button{
    width: 100%;
    height: 100%;
    font-size: 16px;
    border: 1px solid var(--border-light-color);
    cursor: pointer;
    background-color: var(--background-color);
    color: var(--text-color);
}
.product-display .product-main .product-details .size-menu .menu-options .option button.active{
    border: 2px solid var(--border-color);
}

.product-display .product-main .product-details .product-cta{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
.product-display .product-main .product-details .product-cta .add-to-cart{
    width: 150px;
    border: 1px solid var(--border-light-color);
    margin-top: 30px;
}
.product-display .product-main .product-details .product-cta .add-to-cart button{
    width: 100%;
    height: 50px;
    /* border: 1px solid var(--border-color); */
    background-color: var(--secondary-color);
    color: var(--primary-color);
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    outline: none;
    border: none;
}

.product-display .information{
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 100px;
    padding: 50px;
    position: relative;
    overflow: hidden;
}
.product-display .information .line{
    width: 93.5%;
    height: 1px;
    background-color: var(--border-light-color);
    position: absolute;
    /* left: 50px; */
    top: 0;
}
.product-display .information .info-block{
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.product-display .information .info-block .info-title{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.product-display .information .info-block .label{
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}
.product-display .information .info-block .content{
    font-size: 15px;
    width: 100%;
    opacity: 0.9;
}
.product-display .information .info-block .content a{
    color: var(--text-color);
    text-decoration: underline;
}

.product-display .core-features{
    margin-top: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    padding: 0px 50px;
}
.product-display .core-features .core-features-head{
    text-align: left;
}
.product-display .core-features .core-features-head .title{
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
}
.product-display .core-features .line{
    width: 100%;
    height: 1px;
    background-color: var(--border-light-color);
}
.product-display .core-features .features{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-transform: capitalize;
}
.product-display .core-features .features ul li span.label{
    /* font-weight: 600; */
    color: var(--text-light-color);
}

.product-display .journey{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 50px;
}
.product-display .journey .journey-head{
    width: 100%;
    text-align: left;
}
.product-display .journey .journey-head .title{
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
}
.product-display .journey .line{
    width: 100%;
    height: 1px;
    background-color: var(--border-light-color);
}
.product-display .journey .process{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.product-display .journey .journey-block{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.product-display .journey .journey-block .block-number{
    font-size: 40px;
    font-weight: 600;
    color: var(--text-color);
    font-family: Platypi;
}
.product-display .journey .journey-block .block-image{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.product-display .journey .journey-block .block-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.product-display .journey .journey-block .block-text{
    width: 100%;
    text-align: left;
    color: var(--text-color);
    opacity: 0.8;
}


.product-display .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.product-display .overlay.hide{
    display: none;
}
.product-display .overlay .cart-popup{
    width: 300px;
    height: 200px;
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid var(--border-light-color);
}
.product-display .overlay .cart-popup.hide{
    display: none;
}
.product-display .overlay .cart-popup .cart-popup-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 90%;
    padding: 20px;
}
.product-display .overlay .cart-popup .cart-popup-cta{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}
.product-display .overlay .cart-popup .cart-popup-cta .btn{
    width: 100%;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    color: var(--text-color);
}
.product-display .overlay .sizechart-popup{
    width: auto;
    height: auto;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.product-display .overlay .sizechart-popup .sizechart-popup-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
}
.product-display .overlay .sizechart-popup .sizechart-popup-content img{
    width: auto;
    height: 500px;
}
.product-display .overlay .sizechart-popup .close.btn{
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--text-color);
}
.product-display .overlay .sizechart-popup.hide{
    display: none;
}


@media screen and (max-width: 1230px){
    .product-display .product-main .product-gallery{
        width: 60%;
    }
}
@media screen and (max-width: 1000px){
    .product-display .product-main .product-gallery .product-image{
        height: 500px;
    }
}
@media screen and (max-width: 900px){
    .product-display .product-main{
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
    .product-display .product-main .product-gallery{
        width: 60%;
        justify-content: space-around;
        align-items: center;
    }
    .product-display .product-main .product-gallery .product-image{
        height: 500px;
    }
    .product-display .product-main .product-details{
        width: 100%;
    }
    .product-display .product-main .product-details .product-title{
        font-size: 30px;
    }
    .product-display .product-main .product-details .product-price{
        flex-direction: column;
        gap: 10px;
    }
    .product-display .product-main .product-details .size-menu{
        display: flex;
    }
    .product-display .product-main .product-details .size-menu .label{
        width: 100%;
        justify-content: space-between;
    }
    .product-display .product-main .product-details .size-menu .line{
        width: 100%;
    }
    .product-display .product-main .product-details .size-menu .menu-options{
        flex-direction: row;
        gap: 10px;
    }
    .product-display .product-main .product-details .product-cta{
        flex-direction: column;
        gap: 20px;
    }
    .product-display .product-main .product-details .product-cta .add-to-cart{
        width: 100%;
    }
    .product-display .information{
        flex-direction: column;
        gap: 50px;
    }
    .product-display .information .info-block{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .product-display .information .info-block .info-title{
        width: 100%;
    }
    .product-display .information .info-block .content{
        width: 100%;
    }
    .product-display .journey .process{
        flex-direction: column;
        gap: 50px;
    }
    .product-display .journey .journey-block .block-image{
        width: 300px;
        height: 300px;
    }
}

@media screen and (max-width: 700px) {
    .product-display .product-main .product-gallery{
        width: 100%;
    }
    .product-display .product-main .product-gallery .product-image{
        height: 500px;
    }
    .product-display .overlay .sizechart-popup{
        width: 80%;
    }
    .product-display .overlay .sizechart-popup .close.btn{
        top: 20px;
        right: 20px;
    }
    .product-display .product-main{
        padding: 20px 20px;
    }
    .product-display .core-features{
        padding: 0px 20px;
    }
    .product-display .journey{
        padding: 50px 20px;
    }
    .product-display .information{
        padding: 50px 20px;
    }
}