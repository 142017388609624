:root{
    --order-box-height: 250px;
}

.section-orders{
    margin-top: 100px;
    margin-bottom: 20px;
    color: var(--text-color);
}
.section-orders *{
    font-family: 'Poppins', sans-serif;
}
.section-orders .section-head{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 50px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
}
.section-orders .section-head .section-title{
    text-transform: uppercase;
    font-family: "Monument Extended";
}

.section-orders .orders{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
    padding: 0 50px;
}
.section-orders .orders .order{
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    width: 40%;
    height: var(--order-box-height);
    gap: 50px;
    border-bottom: 1px solid var(--border-light-color);
    padding-bottom: 70px;
    position: relative;
}

.section-orders .orders .order .product-image{
    width: 200px;
    height: 100%;
    overflow: hidden;
}
.section-orders .orders .order .product-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.section-orders .orders .order .product-info{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}
.section-orders .orders .order .product-info div{
    display: flex;
    gap: 5px;
}
.section-orders .orders .order .product-info div .label{
    font-size: 14px;
    font-weight: 500;
    color: var(--text-light-color);
    text-transform: capitalize;
}
.section-orders .orders .order .product-info div .value{
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
    text-transform: capitalize;
}
.section-orders .orders .order .product-info .details{
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.section-orders .orders .order .product-info .details .details-head{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}
.section-orders .orders .order .product-info .details .details-head .product-name{
    font-size: 25px;
    font-weight: 600;
    text-transform: capitalize;
}
.section-orders .orders .order .product-info .details .details-head .price{
    font-size: 20px;
    font-weight: 500;
    color: var(--text-color);
}
.section-orders .orders .order .product-info .details .color{
    margin-top: 10px;
}
.section-orders .orders .order .product-info .order-info{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
}
.section-orders .orders .order .product-info .order-info .order-id .value{
    text-transform: uppercase;
}
.section-orders .orders .order .order-actions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    position: absolute;
    bottom: 10px;
    width: 100%;
}
.section-orders .orders .order .order-actions .btn.view{
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
}

@media screen and (max-width: 1000px){
    .section-orders .orders .order{
        width: 80%;
    }
}
@media screen and (max-width: 600px){
    .section-orders .orders .order{
        gap: 30px;
    }
    .section-orders .orders .order .product-info .details .details-head .product-name{
        font-size: 20px;
    }
}
@media screen and (max-width: 500px){
    .section-orders .section-head{
        padding-left: 20px;
    }
    .section-orders .orders{
        justify-content: center;
        align-content: center;
        padding: 20px;
    }
    .section-orders .orders .order{
        gap: 10px;
        flex-direction: column;
        padding-bottom: 0;
        margin-bottom: 0px;
    }
    .section-orders .orders .order .product-image{
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .section-orders .orders .order .product-image img{
        width: 50%;
        height: 100%;
        object-fit: cover;
    }
    :root{
        --order-box-height: fit-content;
    }
    .section-orders .orders .order .product-info{
        margin-top: 30px;
    }
    .section-orders .orders .order .order-actions{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    .section-orders .orders .order .order-actions .btn.view{
        background-color: var(--secondary-color);
        color: var(--primary-color);
        font-size: 14px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
}
@media screen and (max-width: 400px){
    /* image */
    .section-orders .orders .order .product-image img{
        width: 100%;
    }
}