.order-confirmed{
    margin-top: 100px;
    margin-bottom: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
}
.order-confirmed *{
    font-family: Poppins;
}
.order-confirmed .main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 20px;
    width: auto;
}
.order-confirmed .main .order-image{
    height: 100%;
    width: 300px;
    border-radius: 20px;
    display: none;
}
.order-confirmed .main .order-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.order-confirmed .main .order-info{
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
    color: var(--text-color);
}
.order-confirmed .main .order-info .note{
    width: 100%;
    text-align: center;
}
.order-confirmed .main .order-info .title{
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.order-confirmed .cta{
    position: absolute;
    bottom: -50px;
    right: 0;
    /* display: none; */
    width: 100%;
}
.order-confirmed .cta .btn{
    background-color: var(--secondary-color);
    color: var(--primary-color);
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1.1rem;
    text-decoration: none;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 850px) {
    .order-confirmed .main{
        flex-direction: column;
        gap: 20px;
    }
    .order-confirmed .main .order-image{
        width: 50%;
        height: 300px;
    }
    
}