.order-details{
    margin-top: 100px;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.order-details *{
    font-family: Poppins;
}

.order-details .section-head{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: var(--text-color);
    padding: 0 50px;
    border-bottom: 1px solid var(--border-light-color);
    padding-bottom: 10px;
}
.order-details .section-head .section-title .title{
    font-size: 30px;
    font-weight: 600;
    font-family: 'Monument Extended';
    color: var(--text-color);
    text-transform: uppercase;
}

.order-details .order-info{
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 60px;
    padding: 0 50px;
}
.order-details .order-info div{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: auto;
}
.order-details .order-info div .label{
    font-size: 16px;
    color: var(--text-light-color);
}
.order-details .order-info div .value{
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
    color: var(--text-color);
}

.order-details .main{
    display: flex;
    flex-direction: row;
    color: var(--text-color);
}
.order-details .main .left{
    display: flex;
    flex-direction: column;
    width: 35%;
}
.order-details .main .left .customer-details{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0 20px 50px;
    border-bottom: 1px solid var(--border-light-color);
}
.order-details .main .left .customer-details .customer-info{
    display: flex;
    flex-direction: column;
}
.order-details .main .left .customer-details .customer-info .label{
    font-size: 16px;
    color: var(--text-color);
    text-transform: capitalize;
}
.order-details .main .left .customer-details .customer-info .value{
    font-size: 14px;
    color: var(--text-light-color);

}
.order-details .main .left .payment-info{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0 20px 50px;
    border-bottom: 1px solid var(--border-light-color);
}
.order-details .main .left .payment-info div{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.order-details .main .left .payment-info div .label{
    font-size: 16px;
    color: var(--text-color);
}
.order-details .main .left .payment-info div .value{
    font-size: 14px;
    color: var(--text-light-color);
}
.order-details .main .left .payment-info .payment-status .value, .order-details .main .left .payment-info .payment-mode .value{
    text-transform: uppercase;
}
.order-details .main .left .payment-info .btn.pay{
    background-color: var(--secondary-color);
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    color: var(--primary-color);
    cursor: pointer;
    width: fit-content;
}

.order-details .main .left .cta{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0 20px 50px;
    border-bottom: 1px solid var(--border-light-color);
}
.order-details .main .left .cta .order-cta div{
    width: fit-content;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
}
.order-details .main .left .cta .help-cta div{
    text-decoration: underline;
    cursor: pointer;
}

.order-details .main .right{
    padding: 50px 0px;
    border-left: 1px solid var(--border-light-color);
    width: 60%;
}
.order-details .main .right .top{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 50px;
}
.order-details .main .right .top .left{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.order-details .main .right .top .left .order-status{
    display: flex;
    flex-direction: column;
}
.order-details .main .right .top .left .order-status .label{
    font-size: 16px;
    color: var(--text-color);
}
.order-details .main .right .top .left .order-status .value{
    font-size: 40px;
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: bold;
}
.order-details .main .right .top .left .order-status .edd-info{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}
.order-details .main .right .top .left .order-status .edd-info .label{
    font-size: 16px;
    color: var(--text-color);
}
.order-details .main .right .top .left .order-status .edd-info .value{
    font-size: 14px;
    color: var(--text-light-color);
    font-weight: 500;
}

.order-details .main .right .top .left .order-items{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
}
.order-details .main .right .top .left .order-items .title{
    font-size: 20px;
    color: var(--text-color);
    font-weight: 600;
}
.order-details .main .right .top .left .order-items .item{
    display: flex;
    gap: 20px;
    border-radius: 5px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-light-color);
}
.order-details .main .right .top .left .order-items .item-image{
    width: 150px;
    height: 200px;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
}
.order-details .main .right .top .left .order-items .item-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.order-details .main .right .top .left .order-items .item-details{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.order-details .main .right .top .left .order-items .item-details .details-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.order-details .main .right .top .left .order-items .item-details .details-head .item-name{
    font-size: 16px;
    font-weight: 600;
}
.order-details .main .right .top .left .order-items .item-details .details-head .item-price{
    font-size: 16px;
    font-weight: 600;
}
.order-details .main .right .top .left .order-items .item-details .details-body{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    text-transform: capitalize;
}
.order-details .main .right .top .left .order-items .item-details .details-body .label{
    font-size: 14px;
    color: var(--text-light-color);
}
.order-details .main .right .top .left .order-items .item-details .details-body .value{
    font-size: 14px;
    color: var(--text-color);
}

.order-details .main .right .order-tracking{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    border-top: 1px solid var(--border-light-color);
    padding: 20px 50px;
}
/* .order-details .main .right .order-tracking .tracking-btn{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.order-details .main .right .order-tracking .tracking-btn .label{
    font-size: 20px;
    color: var(--text-color);
}
.order-details .main .right .order-tracking .tracking-btn .btn{
    background-color: var(--secondary-color);
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-color);
    cursor: pointer;
    width: 150px;
} */
.order-details .main .right .order-tracking .tracking-btn a{
    color: var(--text-light-color);
    text-decoration: underline;
}

.order-details .main .right .order-tracking .order-timeline{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}
.order-details .main .right .order-tracking .order-timeline .edd-info{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.order-details .main .right .order-tracking .order-timeline .edd-info .label{
    font-size: 16px;
    color: var(--text-light-color);
}
.order-details .main .right .order-tracking .order-timeline .edd-info .value{
    font-size: 20px;
    color: var(--text-color);
}

.order-details .main .right .order-tracking .order-timeline .timeline{
    display: flex;
    flex-direction: column;
}
.order-details .main .right .order-tracking .order-timeline .timeline .activity{
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 80px;
}
.order-details .main .right .order-tracking .order-timeline .timeline .activity .date-time{
    font-size: 14px;
    display: flex;
    flex-direction: column;
    width: 200px;
}
.order-details .main .right .order-tracking .order-timeline .timeline .activity .date-time .date{
    font-size: 16px;
    color: var(--text-color);
}
.order-details .main .right .order-tracking .order-timeline .timeline .activity .date-time .time{
    font-size: 14px;
    color: var(--text-light-color);
}
.order-details .main .right .order-tracking .order-timeline .timeline .activity .progress{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: inherit;
    position: relative;
}
.order-details .main .right .order-tracking .order-timeline .timeline .activity .progress .line{
    rotate: 180deg;
    width: 1px;
    height: inherit;
    position: absolute;
    background-color: var(--border-light-color);
}
.order-details .main .right .order-tracking .order-timeline .timeline .activity .progress .circle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    margin-top: 10px;
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* transform: translateY(-50%); */
}
.order-details .main .right .order-tracking .order-timeline .timeline .activity .progress .circle.active{
    background-color: green;
}

.order-details .main .right .order-tracking .order-timeline .timeline .activity .activity-details{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.order-details .main .right .order-tracking .order-timeline .timeline .activity .activity-details .status{
    font-size: 16px;
    color: var(--text-color);
}
.order-details .main .right .order-tracking .order-timeline .timeline .activity .activity-details .location{
    font-size: 14px;
    color: var(--text-light-color);
}



@media screen and (max-width: 900px){
    .order-details .section-head{
        align-items: flex-start;
        flex-direction: column;
    }
    .order-details .section-head .order-info{
        margin-top: 20px;
        padding: 0;
    }
}
@media screen and (max-width: 800px){
    .order-details .main{
        flex-direction: column-reverse;
    }
    .order-details .main .left{
        width: 100%;
        border-left: none;
    }
    .order-details .main .left .customer-details{
        border-top: 1px solid var(--border-light-color);
    }
    .order-details .main .right{
        width: 100%;
        border-left: none;
    }
    .order-details .main .right .top .left{
        width: 100%;
    }
    .order-details .main .right .top .left .order-items .item{
        flex-direction: row;
        width: 100%;
        border: none;
    }
    .order-details .main .right .top .left .order-items .item-image{
        width: 100px;
        height: 150px;
    }
    .order-details .main .right .top .left .order-items .item-details{
        width: 100%;
    }
    .order-details .main .right .top .left .order-items .item-details .details-head .item-name{
        margin-bottom: 10px;
    }
    .order-details .main .right .order-tracking{
        padding: 50px 50px;
    }
    .order-details .main .right .order-tracking .order-timeline .timeline .activity .date-time{
        width: 100px;
    }
    .order-details .main .right .order-tracking .order-timeline .timeline .activity .progress{
        height: 100px;
    }
    .order-details .main .right .order-tracking .order-timeline .timeline .activity .progress .line{
        height: 100%;
    }
    .order-details .main .right .order-tracking .order-timeline .timeline .activity .progress .circle{
        margin-top: 0;
    }
}
@media screen and (max-width: 540px){
    .order-details .section-head .order-info{
        gap: 20px;
    }
    .order-details .section-head .order-info div .label{
        font-size: 14px;
    }
    .order-details .section-head .order-info div .value{
        font-size: 12px;
    }
}