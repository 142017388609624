.featured-products {
    margin: 100px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--background-color);
}
.featured-products .section-head {
    margin: 0 20px;
    font-family: "Monument Extended";
    color: var(--text-color);
}
.featured-products .section-head .section-title {
    text-transform: uppercase;
}
.featured-products .products {
    width: 80%;
    display: flex;
    justify-content: center;
    padding: 0 30px;
    gap: 20px;
}
.featured-products .products .item{
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
}
.featured-products .products .item img:hover{
    transform: scale(1.1);
    transition: all 0.3s ease;
}

.featured-products .products .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}
.featured-products .products .frame {
    width: 100%;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.featured-products .products .frame .item:first-child {
    width: 100%;
    height: 45%;
}
.featured-products .products .frame .item:last-child {
    width: 100%;
    height: 55%;
}
.featured-products .products .frame2{
    flex-direction: column-reverse;
}

.featured-products .mobile-products{
    display: flex;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}
.featured-products .mobile-products.hide{
    display: none;
}
.featured-products .mobile-products .item{
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    margin: 5px;
}
.featured-products .mobile-products .item img:hover{
    transform: scale(1.1);
    transition: all 0.3s ease;
}
.featured-products .mobile-products .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}
.featured-products .mobile-products .frame {
    width: 100%;
    height: 1000px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.featured-products .mobile-products .frame .item:first-child {
    width: 100%;
    height: 30%;
}
.featured-products .mobile-products .frame .item:nth-child(2) {
    width: 100%;
    height: 30%;
}
.featured-products .mobile-products .frame .item:last-child {
    width: 100%;
    height: 40%;
}
.featured-products .mobile-products .frame2{
    flex-direction: column-reverse;
}

@media screen and (max-width: 1150px) {
    .featured-products .products {
        width: 90%;
    }
}
@media screen and (max-width: 950px) {
    .featured-products .products {
        width: 90%;
    }
    .featured-products .products .frame{
        height: 800px;
    }
}
@media screen and (max-width: 850px) {
    .featured-products .products {
        width: 90%;
    }
    .featured-products .products .frame{
        height: 700px;
    }
}
@media screen and (max-width: 750px) {
    .featured-products .products {
        display: none;
    }
    .featured-products .mobile-products.hide{
        display: flex;
    }
}
@media screen and (max-width: 550px) {
    .featured-products .mobile-products .frame{
        height: 800px;
    }
    .featured-products .mobile-products .frame .item:first-child {
        height: 30%;
    }
    .featured-products .mobile-products .frame .item:nth-child(2) {
        height: 30%;
    }
    .featured-products .mobile-products .frame .item:last-child {
        height: 40%;
    }
}