/* .section-reset-password {
  margin-top: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-reset-password *{
  font-family: Poppins;
}

.section-reset-password .reset-password-form {
  width: 400px;
  height: auto;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: var(--quaternary-color);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  gap: 15px;
}
.section-reset-password .reset-password-form .section-title {
  font-size: 25px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.section-reset-password .reset-password-form .message{
  margin-top: -20px;
  padding: 0px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: var(--danger-color);
  margin-bottom: 10px;
}
.section-reset-password .reset-password-form .message.hide{
  display: none;
}
.section-reset-password .reset-password-form .message p{
  margin: 0;
}

.section-reset-password .reset-password-form .form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.section-reset-password .reset-password-form .form.hide {
  display: none;
}
.section-reset-password .reset-password-form .form .form-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5px;
  width: 100%;
}
.section-reset-password .reset-password-form .form .form-group label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
}
.section-reset-password .reset-password-form .form .form-group input {
  width: auto;
  height: 40px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  outline: none;
}
.section-reset-password .reset-password-form .form .form-group .btn {
  width: 100%;
  height: 40px;
  background-color: var(--secondary-color);
  color: var(--quaternary-color);
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.section-reset-password .reset-password-form .confirmation {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.section-reset-password .reset-password-form .confirmation .message {
  font-size: 15px;
  font-weight: 500;
  color: var(--success-color);
  text-align: center;
}
.section-reset-password .reset-password-form .confirmation.hide {
  display: none;
}

.section-reset-password .expired-token {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.section-reset-password .expired-token .message {
  font-size: 15px;
  font-weight: 500;
  color: var(--danger-color);
  text-align: center;
} */










:root{
    --login-field-height: 25px;
    --login-button-height: 50px;
}

.section-reset-password {
    margin-top: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-reset-password *{
    font-family: Poppins;
}

.section-reset-password .reset-password-form {
    width: 400px;
    height: auto;
    padding: 40px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    /* background-color: var(--quaternary-color); */
    border: 2px solid var(--border-light-color);
    border-radius: 20px;
    gap: 15px;
}
.section-reset-password .reset-password-form .section-title {
    font-size: 25px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}
.section-reset-password .reset-password-form .message{
  margin-top: -20px;
  padding: 0px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: var(--error-color);
  margin-bottom: 10px;
}
.section-reset-password .reset-password-form .message.hide{
  display: none;
}
.section-reset-password .reset-password-form .message p{
  margin: 0;
}
.section-reset-password .reset-password-form .form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.section-reset-password .reset-password-form .form .form-group{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}
.section-reset-password .reset-password-form .form .form-group label{
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  color: var(--text-color);
}
.section-reset-password .reset-password-form .form .form-group input{
  width: auto;
  height: var(--login-field-height);
  padding: 10px;
  border: 1px solid var(--border-light-color);
  border-radius: 5px;
  color: var(--input-text-color);
  background-color: var(--input-background-color);
  outline: none;
  font-family: Poppins;
  font-size: 15px;
}
.section-reset-password .reset-password-form .form .form-group .btn{
  width: 100%;
  padding: 10px;
  height: var(--login-button-height);
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: 1px solid var(--border-light-color);
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.section-reset-password .confirmation{
  width: 550px;
  height: auto;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  border: 2px solid var(--border-light-color);
  border-radius: 20px;
}
.section-reset-password .confirmation .section-title{
  font-size: 25px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.section-reset-password .confirmation .message{
  padding: 0px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 10px;
}

.section-reset-password .reset-password-form.hide{
  display: none;
}
.section-reset-password .confirmation.hide{
  display: none;
}
.section-reset-password .expired-token {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.section-reset-password .expired-token .message {
  font-size: 15px;
  font-weight: 500;
  color: var(--danger-color);
  text-align: center;
}