.hero{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
}
.hero .back-text{
    display: flex;
    justify-content: center;
    height: inherit;
    flex-direction: column;
    padding: 0 50px;
}
.hero .back-text .sub-text{
    margin-top: -70px;
    padding-left: 15px;
}

.hero .back-text .main-text .text{
    font-size: 17.5vw;
    font-weight: 700;
    color: var(--text-light-color);
    opacity: 0.4;
    font-family: 'Monument Extended';
    text-align: center;
    text-transform: uppercase;
    z-index: 0;
    display: inline-block; /* Allows the element to shrink-wrap */
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden; /* Prevents overflow */
    text-overflow: ellipsis; /* Optional, to indicate overflow */
}
.hero .back-text .sub-text .text{
    font-size: 2.5rem;
    font-weight: 700;
    opacity: 0.4;
    color: var(--text-light-color);
    font-family: 'Monument Extended';
    text-align: left;
    text-transform: uppercase;
    z-index: 0;
}
.hero .product-image{
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 1;
}
.hero .product-image img{
    position: absolute;
    bottom: 0;
    width: auto;
    height: 100%;
}
.hero .story{
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--text-color);
    max-width: 45%;
    z-index: 1;
    padding: 0 0 20px 50px;
    font-family: 'Segoe UI';
    opacity: 0.8;
}
.hero .story .story-title{
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: -10px;
}
.hero .story .story-description{
    font-size: 14px;
    font-weight: 400;
}
.hero .story .btn.cta{
    margin-top: 10px;
    padding: 10px 50px;
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid var(--secondary-color);
    border-radius: 100px;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
    background-color: var(--background-color);
}
.hero .line{
    position: absolute;
    bottom: 0;
    width: 95%;
    height: 1px;
    background-color: var(--secondary-color);
    z-index: 1;
}









.hero {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    perspective: 1500px; /* Stronger perspective effect */
    overflow: hidden;
}
.hero .back-text {
    display: flex;
    justify-content: center;
    height: inherit;
    flex-direction: column;
    padding: 0 50px;
}
.hero .product-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform-style: preserve-3d; /* Preserve 3D effect */
    perspective: 1000px; /* Enhance 3D rotation */
}
.hero .product-image img {
    width: auto;
    height: 92%;
    transform-origin: center;
    border-radius: 10px; /* Optional for a polished look */
}
.hero .line {
    position: absolute;
    bottom: 0;
    width: 95%;
    height: 1px;
    background-color: var(--border-light-color);
    z-index: 1;
}

.hero .back-text .text{
    animation: blink 0.7s step-end infinite;
}
@keyframes blink {
    from, to {
      border-color: transparent;
    }
    50% {
      border-color: white;
    }
  }
.hero .back-text .text .Typewriter__cursor{
    display: none;
}

@media screen and (max-width: 1200px){
    .hero .product-image img{
        height: 95%;
    }
    .hero .back-text .sub-text{
        margin-top: -60px;
    }
}
@media screen and (max-width: 1000px){
    .hero .product-image img{
        height: 90%;
    }
    .hero .back-text .sub-text{
        margin-top: -40px;
    }    
}
@media screen and (max-width: 800px){
    .hero .product-image img{
        height: 85%;
    }
    .hero .back-text .sub-text{
        margin-top: -30px;
    }
    .hero .story {
        max-width: 100%;
        padding: 0 0 40px 20px;
    }
    .hero .back-text .sub-text .text{
        font-size: 2rem;
    }
}
@media screen and (max-width: 600px){
    .hero .product-image img{
        height: 75%;
    }
    .hero .back-text {
        justify-content: flex-start;
        margin-top: 150px;
    }
    .hero .back-text .sub-text{
        margin-top: -20px;
    }
    .hero .back-text .sub-text .text{
        font-size: 1.5rem;
    }
    .hero .story {
        bottom: 60px;
        margin-right: 20px;
    }
    .hero .story .btn.cta{
        margin-top: 5px;
        padding: 10px 30px;
        font-size: 16px;
    }
}