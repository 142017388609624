:root{
    --icon-height: 20px;
    --icon-width: 20px;
    --icon-hover-width: 25px;
    --icon-hover-height: 25px;
    --icon-font-size: 20px;
}

.header{
    /* padding: 10px 0px 0 0px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    /* padding-bottom: 12px; */
    z-index: 2;
}
.header *{
    font-family: Poppins;
}
.fixed {
    position: fixed;
    top: 0;
    background-color: var(--navbar-background-color);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    z-index: 5;
}
.header .header-main{
    padding: 0 20px 0 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 50px;
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
}
.header .header-main .header-left{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.header .logo{
    width: 70px;
    height: auto;
    line-height: 0px;
}
.header .logo img{
    width: 100%;
    height: auto;
}
.header .header-right{
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    line-height: 0px;
    gap: 35px;
}
.header .search{
    width: var(--icon-width);
    height: var(--icon-height);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-size: var(--icon-font-size);
    color: var(--secondary-color);
    transition: all 0.3s ease;
}

.header .cart{
    width: var(--icon-width);
    height: var(--icon-height);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-weight: bold;
}
.header .cart img{
    width: 100%;
    height: auto;
}
.header .cart .cart-bubble{
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--secondary-color);
}
.header .cart span{
    font-size: 13px;
    color: var(--text-color);
    font-weight: bold;
    display: none;
}
.header .profile{
    width: var(--icon-hover-width);
    height: var(--icon-hover-height);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header .profile img{
    width: 100%;
    height: auto;
}
.header .profile-menu{
    position: absolute;
    top: 70px;
    right: 10px;
    background-color: var(--background-color);
    padding: 20px 25px 20px 25px;
    border-radius: 10px;
    min-width: 200px;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* display: none; */
    border: 1px solid var(--border-light-color);
    z-index: 100;
}
.header .profile-menu .user-info{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    padding: 0 0 10px 0;
    color: var(--text-color);
    margin-bottom: 20px;
}
.header .profile-menu .user-info .name{
    font-weight: bold;
}
.header .profile-menu .user-info .email{
    font-size: 13px;
    opacity: 0.7;
}
.header .profile-menu .menu-items{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}
.header .profile-menu .menu-items .profile-menu-item{
    display: flex;
    padding: 15px 0;
}
.header .profile-menu .menu-items a{
    color: var(--text-color);
    text-decoration: none;
    width: 100%;
}
.header .profile-menu .menu-items .profile-menu-item a.hide{
    display: none;
}