:root{
    --login-field-height: 25px;
    --login-button-height: 50px;
}

.section-login{
    margin-top: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-login *{
    font-family: Poppins;
}
.section-login .login-form{
    width: 400px;
    height: auto;
    padding: 40px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    /* background-color: var(--quaternary-color); */
    border: 2px solid var(--border-light-color);
    border-radius: 20px;
    gap: 15px;
}
.section-login .login-form .section-title{
    font-size: 25px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}
.section-login .login-form .message{
    margin-top: -20px;
    padding: 0px;
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: var(--error-color);
    margin-bottom: 10px;
}
.section-login .login-form .message.hide{
    display: none;
}
.section-login .login-form .message p{
    margin: 0;
}
.section-login .login-form .form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}
.section-login .login-form .form .form-group{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}
.section-login .login-form .form .form-group label{
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    color: var(--text-color);
}
.section-login .login-form .form .form-group input{
    width: auto;
    height: var(--login-field-height);
    padding: 10px;
    border: 1px solid var(--border-light-color);
    border-radius: 5px;
    color: var(--input-text-color);
    background-color: var(--input-background-color);
    outline: none;
    font-family: Poppins;
    font-size: 15px;
}
.section-login .login-form .form .form-group .btn{
    width: 100%;
    padding: 10px;
    height: var(--login-button-height);
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    border: 1px solid var(--border-light-color);
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.section-login .login-form .cta-action{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.section-login .login-form .cta-action .forgot-password{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 12px;
    color: var(--text-color);
}
.section-login .login-form .cta-action .forgot-password a{
    text-decoration: none;
    font-weight: bold;
    color: var(--text-color);
}
.section-login .login-form .cta-action .go-to-register{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 12px;
    color: var(--text-color);
}
.section-login .login-form .cta-action .go-to-register a{
    text-decoration: none;
    color: var(--text-color);
    font-weight: bold;
}

.section-login .login-form .line{
    width: 100%;
    height: 1px;
    /* background-color: var(--border-light-color); */
    margin: 20px 0;
    background: radial-gradient(circle, var(--border-light-color) 0%, var(--background-color) 100%);
}

.section-login .login-form .google-login-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 12px;
}
.section-login .login-form .google-login-wrapper .google-login-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    border: 1px solid var(--border-light-color);
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 600px) {
    .section-login{
        margin-top: 120px;
    }
    .section-login .login-form{
        width: 90%;
        padding: 20px;
        background-color: transparent;
        box-shadow: none;
        border: none;
    }
    .section-login .login-form .section-title{
        font-size: 20px;
    }
    .section-login .login-form .form{
        gap: 12px;
    }
    .section-login .login-form .form .form-group label{
        font-size: 12px;
    }
    .section-login .login-form .form .form-group input{
        padding: 8px;
    }
    .section-login .login-form .form .form-group .btn{
        height: 35px;
        font-size: 13px;
    }
}
@media screen and (max-width: 400px) {
    .section-login .login-form{
        width: 90%;
        padding: 20px;
        gap: 5px;
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
    .section-login .login-form .section-title{
        font-size: 20px;
    }
    .section-login .login-form .form{
        gap: 5px;
    }
}