/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Platypi:ital,wght@0,300..800;1,300..800&display=swap');
@font-face {
    font-family: 'Fashion Wacks';
    src: url('Components/Assets/Fonts/fashion-wacks/FashionWacksRegular-51vw8.ttf') format('truetype');
}
@font-face {
    font-family: 'Monument Extended';
    src: url('Components/Assets/Fonts/monument-extended/MonumentExtended-Ultrabold.otf') format('opentype');
}
@font-face {
    font-family: 'Segoe UI';
    src: url('Components/Assets/Fonts/segoe-ui/Segoe UI.ttf') format('truetype');
}
@font-face {
    font-family: 'Dx Alegant';
    src: url('Components/Assets/Fonts/dx-alegant/dxalegant-extralight-free-personal-use.otf') format('opentype');
}


:root{
    --primary-bg-color: #ffffff;
    --primary-color: #000000;
    --secondary-color: #9747FF;
    --tertiary-color: #6300E4;
    --quaternary-color: #ffffff;
    --primary-cta-color: #9D5BC4;
    --logo-color: #9D5BC4;
    --danger-color: #FF0000;
    /* dark theme */
    /* --primary-bg-color: #000000;
    --primary-color: #ffffff;
    --secondary-color: #ffffff;
    --tertiary-color: #6300E4;
    --quaternary-color: #000000;
    --primary-cta-color: #b8b6b6;
    --logo-color: #9D5BC4;
    --danger-color: #FF0000; */

    /* Dark Theme ------------------------------ */
    /* Primary Palette */
    --primary-color: #000000;
    --secondary-color: #ffffff;
    --accent-color: #9D5BC4;

    /* Backgrounds */
    --background-color: #000000;
    --background-light-color: #777777;
    /* --surface-color: #ffffff; */

    /* Input and Buttons */
    --input-background-color: #27272A;
    --input-text-color: rgba(255, 255, 255, 0.5);
    --button-background-color: #19191B;
    --button-text-color: #ffffff;

    /* Text Colors */
    --text-color: #ffffff;
    --text-light-color: #B9B9B9;
    --text-dull-color: #1D1D1D;

    /* Links */
    --link-color: #9D5BC4;
    --link-hover-color: #6300E4;

    /* Borders & Shadows */
    --border-color: #ffffff;
    --border-light-color: rgba(255, 255, 255, 0.2);
    --shadow-color: #000000;

    /* Disabled State */
    --disabled-color: #8b8b8b;

    /* Navigation */
    --navbar-background-color: #000000;
    --navbar-text-color: #ffffff;

    /* Footer */
    --footer-background-color: #000000;
    --footer-text-color: #ffffff;

    /* Status Colors */
    --error-color: #FF0000;
    --warning-color: #FFA500;

    /* Light Theme ------------------------------ */
    /* Primary Palette */
    /* --primary-color: #ffffff;
    --secondary-color: #9D5BC4;
    --accent-color: #9D5BC4; */

    /* Backgrounds */
    /* --background-color: #ffffff; */
    /* --surface-color: #ffffff; */

    /* Text Colors */
    /* --text-color: #000000;
    --text-light-color: #ffffff; */
    /* --text-dull-color: #1D1D1D; */

    /* Links */
    /* --link-color: #9D5BC4;
    --link-hover-color: #6300E4; */

    /* Borders & Shadows */
    /* --border-color: #000000;
    --shadow-color: #000000; */

    /* Disabled State */
    /* --disabled-color: #8b8b8b; */

    /* Navigation */
    /* --navbar-background-color: #ffffff;
    --navbar-text-color: #000000; */

    /* Footer */
    /* --footer-background-color: #ffffff;
    --footer-text-color: #000000; */

    /* Status Colors */
    /* --error-color: #FF0000;
    --warning-color: #FFA500; */
}
/* edit scrollbar */
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background-color: #181818;
}
::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
}
*{
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
.line-cut{
  text-decoration: line-through;
}
.bold{
  font-weight: bold;
}
.rs-symbol{
  font-family: 'Segoe UI' !important;
}

body{
  background-color: var(--background-color) !important;
}

.top-bar{
  position: absolute;
  top: 0;
  width: 100%;
}
.section-head{
  text-align: center;
}
.section-title{
  font-family: 'Monument Extended';
  font-weight: bold;
  color: var(--text-color);
}
.section-title span{
  color: var(--accent-color);
}
.section-description{
  font-family: Inter;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}


@media screen and (max-width: 600px){
  .section-head{
    padding: 0 10px;
  }
  .section-title{
      font-size: 24px;
  }
  .section-description{
      font-size: 12px;
  }
}