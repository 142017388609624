.shop{
    margin: 130px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}
.shop .shop-title{
    font-size: 48px;
    font-family: Inter;
    font-weight: 400;
    text-transform: capitalize;
    color: var(--text-color);
    opacity: 70%;
    margin-bottom: 20px;
}
.shop .products{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
}
.shop .products .items{
    padding: 0 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

@media screen and (max-width: 1360px) {
    .shop .products .items{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 550px) {
    .shop .products .items{
        gap: 15px;
    }
}
@media screen and (max-width: 520px) {
    .shop .products .items{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 530px) {
    .shop .products{
        grid-template-columns: repeat(2, 2fr);
    }
}
@media screen and (max-width: 370px) {
    .shop {
        margin: 100px 10px;
    }
}