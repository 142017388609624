.footer{
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--primary-color);
    color: var(--quaternary-color);
    position: relative;
    padding: 50px 30px 30px 30px;
    font-family: Inter;
    /* border-top: 1px solid var(--border-color); */
}
.footer .footer-main{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}
.footer .footer-nav{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    text-align: left;
}
.footer .footer-nav .nav-menu{
    margin-right: 70px;
}
.footer .footer-nav ul{
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer .footer-nav .nav-item{
    margin-top: 10px;
    font-family: Inter;
    cursor: pointer;
    text-align: left;
}
.footer .footer-nav .nav-item:hover{
    text-decoration: underline;
}
.footer .footer-nav .nav-item a{
    color: var(--quaternary-color);
    text-decoration: none;
}

.footer .more-info{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}
.footer .more-info .socials{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
    text-align: right;
}
.footer .more-info .socials .social-item{
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
    cursor: pointer;
}
.footer .more-info .socials .social-item a{
    text-decoration: none;
}
.footer .more-info .socials .social-item img{
    height: 100%;
    width: 100%;
}
.footer .more-info .socials h3{
    display: none;
}
.footer .more-info .contact{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: right;
}
.footer .more-info .contact span{
    margin-bottom: 10px;
}
.footer .more-info .contact span a{
    color: var(--quaternary-color);
    text-decoration: none;
}
.footer .more-info .contact span i{
    font-size: 14px;
    color: var(--quaternary-color);
}
.footer .copyright{
    margin-top: 50px;
    width: 100%;
    text-align: center;
}

@media screen and (max-width: 700px) {
    .footer .footer-main{
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
    .footer .footer-nav{
        text-align: left;
        flex-direction: column;
    }
    .footer .footer-nav .nav-menu{
        margin: 20px 0;
    }
    .footer .footer-nav .nav-menu .nav-item{
        text-align: left;
    }
    .footer .more-info .socials{
        position: absolute;
        top: 0;
        left: 20px;
        width: fit-content;
        margin-top: 30px;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
    }
    .footer .more-info .socials .social-item{
        margin: 0 10px;
    }
    .footer .more-info .contact{
        margin-top: 50px;
        text-align: left;
    }
    .footer .copyright{
        text-align: left;
    }
}