.promo{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    color: var(--text-color);
    width: 100%;
    height: 50px;
    text-align: center;
    font-size: 13px;
    z-index: 2000;
    position: relative;
}
.promo p{
    font-family: 'Poppins';
    font-weight: bold;
    padding: 0 10px;
}

@media (max-width: 800px){
    .promo{
        font-size: 13px;
    }
}