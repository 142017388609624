.hamburger-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 20px;
    display: none;
}
.hamburger-menu .menu-icon{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}
.hamburger-menu .menu-icon .bar{
    width: 100%;
    height: 3px;
    background-color: black;
}
.hamburger-menu .sidebar{
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    background-color: var(--quaternary-color);
    z-index: 999;
    font-family: Inter;
}
.sidebar .close-icon{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}
.sidebar .close-icon .bar{
    width: 100%;
    height: 3px;
    background-color: black;
    position: absolute;
    border-radius: 20px;
    opacity: 0.7;
}
.sidebar .close-icon .bar:nth-child(1){
    transform: rotate(45deg);
}
.sidebar .close-icon .bar:nth-child(2){
    transform: rotate(-45deg);
}
.sidebar .user-details{
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}
.sidebar .user-details .profile-icon{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: var(--primary-color);
    border: 4px solid var(--secondary-color);
}
.sidebar .user-details .name{
    margin-top: 0px;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
}
.sidebar .user-details .email{
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-color);
    opacity: 0.7;
}
.sidebar .menu{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    width: 100%;
    height: auto;
}
.sidebar .menu .primary-menu, .sidebar .menu .secondary-menu{
    width: 100%;
}
.sidebar .menu a{
    color: var(--primary-color);
    text-decoration: none;
    width: 100%;
}
.sidebar .menu .menu-item{
    display: flex;
    align-items: center;
    width: 85%;
    gap: 15px;
}
.sidebar .menu .primary-menu .menu-item{
    padding: 25px;
}
.sidebar .menu .secondary-menu .menu-item{
    padding: 15px 25px;
}
.sidebar .menu .line{
    width: 90%;
    height: 1px;
    background-color: var(--primary-color);
    opacity: 0.3;
    margin: 10px 0;
}
.sidebar .menu-cta{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: var(--primary-color);
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
}
.sidebar .menu-cta .cta-btn{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar .menu-cta .cta-btn a{
    color: var(--quaternary-color);
    text-decoration: none;
    font-size: 17px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.sidebar .menu-cta .cart-cta .cart-icon{
    position: relative;
    width: 25px;
    height: auto;
}
.sidebar .menu-cta .cart-cta .cart-icon img{
    width: 100%;
    height: auto;
}
.sidebar .menu-cta .cart-cta .cart-icon .cart-bubble{
    position: absolute;
    top: -7px;
    right: -7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    background-color: var(--secondary-color);
}
.sidebar .menu-cta .cart-cta .cart-icon .cart-bubble span{
    font-size: 10px;
    color: var(--quaternary-color);
    font-weight: bold;
}
.sidebar .menu-cta .line{
    width: 2px;
    height: 70px;
    background-color: var(--quaternary-color);
}

@media screen and (max-width: 700px){
    .hamburger-menu{
        display: flex;
    }
}