.floating-social {
    width: 50px;
    height: auto;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.floating-social .social-con{
    position: relative;
    width: 100%;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    border-radius: 20px 20px 20px 20px;
    background-color: var(--quaternary-color);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.floating-social .social-con .open-social{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    background-color: var(--quaternary-color);
    transition: transform 1s;
}
.floating-social .social-con .open-social a{
}
.floating-social .social-con .open-social .icon{
    position: absolute;
    width: 50%;
    height: auto;
}
.floating-social .social-con .open-social .icon2{
    display: none;
}
.floating-social .social-con .social-div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 50px;
    position: absolute;
    top: 20px;
    gap: 10px;
}
.floating-social .social-con .social-div img{
    width: 25px;
    height: auto;
    cursor: pointer;
    display: none;
}
.floating-social .social-con .social-div img:first-child{
    /* margin-top: 15px; */
}
.floating-social .social-con .social-div img:last-child{
    /* margin-bottom: 10px; */
}