.coming-soon{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: black;
    color: white;
    font-family: "Monument Extended";
    text-transform: uppercase;
}
.coming-soon h1{
    margin: 0;
}
.coming-soon h1 .Typewriter__cursor{
    display: none;
}
.coming-soon .middle-text{
    font-size: 30px;
    margin: 5px 0;
    padding: 20px 30px 10px 30px;
    font-family: Poppins;
    font-weight: 700;
    letter-spacing: 2px;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    font-family: 'Dx Alegant';
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0.5;
}

@media screen and (max-width: 1200px){
    .coming-soon .middle-text{
        font-size: 25px;
    }
    
}
@media screen and (max-width: 900px){
    .coming-soon .middle-text{
        margin: 30px 0;
        font-size: 20px;
    }
}
@media screen and (max-width: 600px){
    .coming-soon .middle-text{
        font-size: 15px;
    }
}
@media screen and (max-width: 400px){
    .coming-soon .middle-text{
        margin: 30px 0;
        font-size: 15px;
    }
}
@media screen and (max-width: 300px){
    .coming-soon .middle-text{
        font-size: 10px;
    }
}