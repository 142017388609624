/* change placeholder color */
::placeholder{
    color: var(--text-color);
}

.checkout{
    margin-top: 100px;
    padding: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
}
.checkout *{
    font-family: 'Poppins', sans-serif;
}
.checkout .section-head{
    display: flex;
    align-items: center;
    padding-left: 50px;
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color);
}
.checkout .section-head .section-title{
    font-size: 30px;
    font-weight: 600;
    font-family: 'Monument Extended';
    color: var(--text-color);
    text-transform: uppercase;
}

.checkout .main{
    padding: 50px 50px;
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.checkout .main .progress{
    color: var(--text-light-color);
    display: flex;
    justify-content: flex-start;
    gap: 30%;
    width: 100%;
    padding-left: 20px;
    border-bottom: 1px solid var(--border-light-color);
    margin-bottom: 50px;
}
.checkout .main .progress .step-title.active{
    color: var(--text-color);
    font-weight: 600;
}
.checkout .main .bottom-cta{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 50px;
}
.checkout .main .bottom-cta .btn{
    width: 100px;
    height: 40px;
    padding: 20px 80px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    font-size: 16px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

/* Step 1 - address selection */
.checkout .main .form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--text-color);
    gap: 40px;
}
.checkout .main .form .form-group{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkout .main .form .form-group label{
    display: none;
}
.checkout .main .form .form-group-row{
    flex-direction: row;
    gap: 50px;
}
.checkout .main .form .form-group-row div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkout .main .form .form-group input{
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: 16px;
    border-radius: 5px;
}
.checkout .main .form .form-group.default{
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.checkout .main .form .form-group.default label{
    color: var(--text-color);
    display: block;
    font-size: 16px;
}
.checkout .main .form .form-group.default input{
    width: 15px;
    height: 15px;
    color: var(--text-color);
    margin-right: 10px;
    -webkit-appearance: none; /* Removes default browser styling */
    -moz-appearance: none;
    appearance: none;
    transition: background-color 0.3s, border-color 0.3s;
    position: relative;
}
.checkout .main .form .form-group.default input:checked{
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}
.checkout .main .form .form-group.default input:checked::before{
    content: '\2713';
    position: absolute;
    top: -3px;
    left: 2px;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
}
.checkout .main .form .form-group.default input:focus {
    outline: none; /* Removes the outline when focused */
    border-color: var(--border-color); /* Border color on focus */
}

.checkout .main .form .btn.save{
    width: 80%;
    height: 40px;
    padding: 20px 60px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    font-size: 16px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.checkout .main .address-con{
    color: var(--text-color);
    width: 100%;
    display: flex;
    flex-direction: column;
}
.checkout .main .address-con .address{
    width: 100%;
    display: flex;
    gap: 20px;
}
.checkout .main .address-con .address .address-select{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--border-color);
    border-radius: 100%;
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: relative;
}
.checkout .main .address-con .address .address-select input{
    position: absolute;
    top: -1.2px;
    left: -0.3px;
    width: 13px;
    height: 13px;
    background-color: var(--background-color);
    border-radius: 100%;
    cursor: pointer;
    -webkit-appearance: none; /* Removes default browser styling */
    -moz-appearance: none;
    appearance: none;
    transition: background-color 0.3s, border-color 0.3s;
    position: relative;
}
.checkout .main .address-con .address .address-select input:checked{
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}
.checkout .main .address-con .address .address-select input:checked::before{
    content: '';
    width: 10px;
    height: 10px;
    background-color: var(--secondary-color);
    border-radius: 100%;
}
.checkout .main .address-con .address .address-select input:focus {
    outline: none; /* Removes the outline when focused */
    border-color: var(--border-color); /* Border color on focus */
}

.checkout .main .address-con .address .address-detail .address-count{
    font-weight: bold;
}

.checkout .main .step-btn{
    width: 100px;
    height: 40px;
    padding: 20px 80px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    font-size: 16px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 20px;
}

/* Step 2 - payment selection */
.checkout .main .main-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.checkout .main .main-content h3{
    margin: 0;
}
.checkout .main .main-content .left{
    width: 50%;
    display: flex;
    flex-direction: column;
    color: var(--text-color);
}
.checkout .main .main-content .left .payment-options{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.checkout .main .main-content .left .payment-options .payment-methods{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.checkout .main .main-content .left .payment-options .payment-methods .payment-method{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
.checkout .main .main-content .left .payment-options .payment-method input{
    -webkit-appearance: none; /* Removes default browser styling */
    -moz-appearance: none;
    appearance: none;
    transition: background-color 0.3s, border-color 0.3s;
    position: relative;
    border: 2px solid var(--border-color);
    border-radius: 100%;
    height: 15px;
    width: 15px;
    cursor: pointer;
}
.checkout .main .main-content .left .payment-options .payment-method input:checked{
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}
.checkout .main .main-content .left .payment-options .payment-method input:checked::before{
    content: '';
    position: absolute;
    top: -3px;
    left: 2px;
    font-size: 20px;
    font-weight: bold;
    color: var(--text-color);
    padding: 10px;
}
.checkout .main .main-content .left .payment-options .payment-method input:focus {
    outline: none; /* Removes the outline when focused */
    border-color: var(--border-color); /* Border color on focus */
}

.checkout .main .main-content .right{
    width: 40%;
    display: flex;
    flex-direction: column;
    color: var(--text-color);
}
.checkout .main .main-content .right .order-summary{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
}
.checkout .main .main-content .right .order-summary .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-light-color);
    font-size: 14px;
}
.checkout .main .main-content .right .order-summary .row.total{
    margin-top: 20px;
    color: var(--text-color);
    font-size: 20px;
    font-weight: 500;
    border-top: 1px solid var(--border-light-color);
    padding-top: 20px;
}
.checkout .main .main-content .right .order-summary .coupon-window{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}
.checkout .main .main-content .right .order-summary .coupon-window .coupon{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
.checkout .main .main-content .right .order-summary .coupon-window .coupon input{
    width: 100%;
    padding: 8px 10px;
    border-bottom: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
    border-right: none;
    background-color: var(--background-color);
    color: var(--text-color);
    border-radius: 5px 0 0 5px;
    outline: none;
}
.checkout .main .main-content .right .order-summary .coupon-window .coupon .btn{
    width: 100px;
    padding: 8px 0;
    font-weight: 600;
    background-color: transparent;
    color: var(--text-color);
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 5px 5px 0;
    border-right: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    outline: none;
}
.checkout .main .main-content .right .order-summary .coupon-window .coupon-list{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.checkout .main .main-content .right .order-summary .coupon-window .coupon-list .coupon-item .coupon-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkout .main .main-content .right .order-summary .coupon-window .coupon-list .coupon-item .coupon-details .name{
    font-size: 16px;
}
.checkout .main .main-content .right .order-summary .coupon-window .coupon-list .coupon-item .coupon-details .cta{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
}
.checkout .main .main-content .right .order-summary .coupon-window .coupon-list .coupon-item .coupon-desc{
    color: var(--text-light-color);
    font-size: 14px;
    margin-top: 5px;
}
.checkout .main .tnc-note{
    color: var(--text-light-color);
    font-size: 14px;
    margin-top: 0px;
}
.checkout .main .tnc-note p{
    margin: 0;
}
.checkout .main .tnc-note a{
    color: var(--text-color);
    text-decoration: underline;
}

/*  Step 3 - summary */
.checkout .main .left .order-items{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.checkout .main .left .order-items .item{
    display: flex;
    gap: 20px;
    border-radius: 5px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-light-color);
}
.checkout .main .left .order-items .item-image{
    width: 150px;
    height: 200px;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkout .main .left .order-items .item-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.checkout .main .left .order-items .item-details{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}
.checkout .main .left .order-items .item-details .details-head{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
}
.checkout .main .left .order-items .item-details .details-head .item-name{
    font-size: 16px;
    font-weight: 600;
}
.checkout .main .left .order-items .item-details .details-head .item-price{
    font-size: 16px;
    font-weight: 600;
}
.checkout .main .left .order-items .item-details .details-body{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    text-transform: capitalize;
}
.checkout .main .left .order-items .item-details .details-body .label{
    font-size: 14px;
    color: var(--text-light-color);
}
.checkout .main .main-content .left .shipping-summary{
    margin-top: 50px;
}

.main .main-content .right{
    padding-top: 20px;
}


@media screen and (max-width: 850px){
    .checkout .main .main-content{
        flex-direction: column;
    }
    .checkout .main .main-content .left{
        width: 100%;
    }
    .checkout .main .main-content .right{
        margin-top: 20px;
        width: 100%;
    }
    .checkout .main .progress{
        gap: 20%;
    }
    .checkout .main .form .form-group{
        width: 100%;
    }
    .checkout .main .form .form-group.default{
        width: 100%;
        justify-content: flex-start;
    }
    .checkout .main .form .btn.save{
        width: 100%;
    }
}
@media screen and (max-width: 700px){
    .checkout .main .form .form-group-row{
        flex-direction: column;
    }
    .checkout .main .form .form-group-row div{
        width: 100%;
    }

    .checkout .main .progress .step-title{
        display: none;
    }
    .checkout .main .progress .step-title.active{
        display: block;
    }
}
@media screen and (max-width: 600px){
    /* reduce left right margin */
    .checkout .main{
        padding: 50px 20px;
    }
    .checkout .section-head{
        padding-left: 20px;
    }
}
@media screen and (max-width: 400px){
    /* button */
    .checkout .main .bottom-cta .btn{
        padding: 10px 40px;
    }
}